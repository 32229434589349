import { FC, useEffect, useState } from "react";
import { StarAnnoIcon } from "../common/Icons";
import { twJoin } from "tailwind-merge";
import { createContext } from "vm";
import { NavLink } from "react-router-dom";
import { useAddToFavoriteMutation, useGetNoticeBoardDataByIDQuery } from "../../app/services/notice-board";
export interface IAnnoucementItemTag {
  id: number;
  name: string;
  color: string;
}
export interface IAnnoucementItem {
  date: string;
  time: string;
  title: string;
  description: string;
  tags: IAnnoucementItemTag[];
  link: string;
  url:string;
  filesize:string;
  readStatus:boolean;
  id:number,
  isFavourite:boolean,
  readNoticeBoardDetail: (id: number) => void
}
export const anno_colors = [""];
const AnnoucementItem: FC<IAnnoucementItem> = ({
  date,
  time,
  title,
  description,
  tags,
  link,
  url,
  readStatus,
  id,
  isFavourite,
  readNoticeBoardDetail,
  filesize
}) => {  
  const [isFill,setIsFill]=useState<boolean>(isFavourite);
  const [addToFavourite]=useAddToFavoriteMutation();
  const checkFavourite=(id:number)=>{
    addToFavourite(id).then((res:any)=>{
      setIsFill(!isFill);
    })
  }
  return (
    <NavLink
      state={{
        data: {
          date,
          time,
          title,
          description,
          tags,
          url,
          id,
          filesize,
          readStatus
        },
      }}
      onClick={()=>readNoticeBoardDetail(id)}
      to={{
        pathname: link,
      }}
      className="block p-3 rounded-lg bg-sat_lightgray mb-3"
    >
      <div className="flex justify-between items-center">
        <p className="text-body-sm text-sat_darkgray400 font-secondary mr-3">
          {date}
          {time}
        </p>
        <button onClick={(e)=>{
          e.preventDefault();
          e.stopPropagation();
          checkFavourite(id)
        }}>
          <StarAnnoIcon color={isFavourite==true?'#FFCC00':'#D9D9D9'} className={twJoin((isFavourite==true)?'fill-[#FFCC00]':'')}/>
        </button>
      </div>
     <div className="mt-2">
        <div className="flex justify-between">
          <p className="heading3 text-sat_darkblackv2 font-bold font-secondary text-left">
            {title}
          </p>
          {readStatus==false?<p className="w-[6px] h-[6px] rounded-full bg-sat_primary mr-[9px]"></p>:''}
        </div>
        <p className="mt-2 text-body-md text-#404040 font-normal font-secondary text-left line-clamp-3" dangerouslySetInnerHTML={{__html:description}}></p>
        <ul className="flex items-center flex-wrap">
          {tags?.map((tag, key) => {
            return (
              <li
                key={key}
                style={{ backgroundColor: tag.color?tag.color:'#6695C1' }}
                className={twJoin(
                  " inline-block mr-1 my-1 last:mr-0 text-text-body-sm text-white font-secondary px-2 py-1 rounded-[13px]"
                )}
              >
                {tag.name}
              </li>
            );
          })}
        </ul>
      </div>    
    </NavLink>
  );
};
export default AnnoucementItem;
