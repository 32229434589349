import { twJoin } from "tailwind-merge";
import styles from './Setting.module.scss';
import i18n from "../../i18n";
import { withNamespaces } from "react-i18next";
import { useNavigate } from "react-router-dom";
export const languages=[
    {
        link:'',
        code:'zh',
        text:'繁體中文'
    },
    {
        link:'',
        code:'zh-cn',
        text:'简体中文'
    },
    {
        link:'',
        code:'en',
        text:'English'
    }
]
const Language=(props:any)=>{
    const navigate = useNavigate();
    const changeLanguage = (lng:any,link:string) => {
        i18n.changeLanguage(lng);
        window.localStorage.setItem('language',lng);
        navigate(link);
      };
    const langstr=window.localStorage.getItem('language');
    return  <div className="max-w-full">
        <div className="max-w-full mx-auto">
            <div className="-mx-5">
            {languages?.map((language,key)=>{
                return <div className="p-5 border-b border-b-sat_borderv2">
                    <button onClick={()=>changeLanguage(language.code,'/setting')} key={key} className={twJoin("flex items-center text-left w-full",langstr==language.code?styles.active_language:'')}>                    
                        <p className="font-normal text-sat_darkblackv2 text-body-md w-full">{language.text}</p>
                    </button>
                </div>
            })}
            </div>
        </div>
    </div>
}
export default withNamespaces((props) => props.namespaces)(Language);