import { useEffect, useState } from "react";
import DateFilter from "../common/DateFilter";
import { PDFIcon } from "../common/Icons";
import { useGetManualsQuery } from "../../app/services/manuals";
import { CircularProgress } from "@mui/material";
import {
  generateYear,
  useGetCategoryAllListQuery,
} from "../../app/services/category";
import { NavLink, useNavigate } from "react-router-dom";
const filterData = [
  {
    id: 1,
    name: "Company",
    color: "#6695C1",
  },
  {
    id: 2,
    name: "Announcement",
    color: "#AF7BA4",
  },
  {
    id: 3,
    name: "Employee",
    color: "#FF9248",
  },
  {
    id: 4,
    name: "Holiday",
    color: "#8F8F8F",
  },
  {
    id: 5,
    name: "IT",
    color: "#82AD57",
  },
];
const years: Number[] = generateYear();
// const manuals=[
//     {
//         id:1,
//         label:'Employee Handbook',
//         link:'https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf'
//     },
//     {
//         id:1,
//         label:'Employee Handbook',
//         link:'https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf'
//     },
//     {
//         id:1,
//         label:'Employee Handbook',
//         link:'https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf'
//     },
//     {
//         id:1,
//         label:'Employee Handbook',
//         link:'https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf'
//     },
//     {
//         id:1,
//         label:'Employee Handbook',
//         link:'https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf'
//     },
//     {
//         id:1,
//         label:'Employee Handbook',
//         link:'https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf'
//     },
//     {
//         id:1,
//         label:'Employee Handbook',
//         link:'https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf'
//     },
//     {
//         id:1,
//         label:'Employee Handbook',
//         link:'https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf'
//     },
//     {
//         id:1,
//         label:'Employee Handbook',
//         link:'https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf'
//     },
//     {
//         id:1,
//         label:'Employee Handbook',
//         link:'https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf'
//     }
// ]
const Manual = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState<string>("");
  const [categoriesData, setCategories] = useState<any>([]);
  const [filterYear, setFilterYear] = useState<string>("");
  const [manuals, setManuals] = useState<any>([]);
  const { data: categories } = useGetCategoryAllListQuery();
  const { data: manualsData, isLoading } = useGetManualsQuery({
    category: filter,
    year: filterYear,
  });
  useEffect(() => {
    if (manualsData) {
      setManuals(manualsData?.ManualList);
    }
  }, [manualsData]);
  useEffect(() => {
    if (categories) {
      setCategories([{ id: 0, name: "All" }, ...categories?.categoryList]);
    }
  }, [categories]);
  const filterByDate = (desc: boolean) => {
    let sortedEvents = [...manuals].sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );
    if (desc) {
      sortedEvents = [...manuals].sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
    }
    setManuals([...sortedEvents]);
  };
  const download = (dataurl: string, filename: string) => {
    const link = document.createElement("a");
    link.href = dataurl;
    link.target = "_blank";
    link.download = filename;
    link.click();
  };
  return (
    <div>
      <DateFilter
        filter={filter}
        setFilter={setFilter}
        years={years}
        setFilterYear={setFilterYear}
        filterYear={filterYear}
        filterData={categoriesData}
        filterByDate={filterByDate}
      />
      {isLoading == true ? (
        <CircularProgress />
      ) : (
        <ul className="mt-4 mb-16 max-h-[68vh] overflow-auto">
          {manuals?.map((manual: any, key: number) => {
            const pathname="/pdfview?" + manual.file_path;
            return (
              <li
                key={key}
                className="bg-sat_lightgray p-3 rounded-lg mb-3 w-full"
              >
                <NavLink
                  to={{pathname:'/pdfview'}}
                  state={
                   { is_download:manual.is_download,filepath: manual.file_path}
                  }
                  className=" w-full"
                >
                  <div className="flex items-center">
                    <PDFIcon
                      width={24}
                      height={24}
                      className="min-w-6 min-h-6"
                    />
                    <p className="text-body-sm font-normal text-sat_darkblackv2 ml-3 text-left">
                      {manual.file_name}
                    </p>
                  </div>
                </NavLink>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
export default Manual;
