import { twJoin } from "tailwind-merge";
import styles from './Setting.module.scss';
import change_password from '../img/change-password.svg';
import faq_icon from '../img/faq-icon.svg';
import useful_icon from '../img/useful-line.svg';
import language_icon from '../img/language-icon.svg';
import terms_condition_icon from '../img/terms-condition.svg';
import privacy_icon from '../img/privacy.svg';
import logout_icon from '../img/logout-icon.svg';
import { FC } from "react";
import { withNamespaces } from "react-i18next";
import { NavLink } from "react-router-dom";
export const settings=[
    {
        icon:change_password,
        text:'change_password',
        link:'/profile/change_password'
    },
    {
        icon:faq_icon,
        text:'faq',
        link:'/faq'
    },
    {
        icon:useful_icon,
        text:'usefulline',
        link:'/profile/useful_lines'
    },
    {
        icon:language_icon,
        text:'language',
        link:'/profile/language'
    },
    {
        icon:terms_condition_icon,
        text:'terms',
        link:'/terms-condition'
    },
    {
        icon:privacy_icon,
        text:'privacy',
        link:'/privacy-policy'
    },
    {
        icon:logout_icon,
        text:'logout',
        link:'/profile/logout'
    }
]
interface IUserInfo {
    loginData: any;
    props:any;
  }
const Setting=(props:any)=>{
    const {t}=props;
    return  <div className="max-w-full">
        <div className="max-w-full mx-auto">
            <div className="-mx-5">
            {settings?.map((setting,key)=>{
                return <NavLink to={{pathname:setting.link}} key={key} className="p-5 border-b border-b-sat_borderv2 flex">
                    <div className={twJoin("flex items-center w-full",styles.setting_arrow)}>
                    <img src={setting.icon} alt={'setting_icon_'+key} className="mr-3"/>
                    <p className="font-normal text-sat_darkblackv2 text-body-md">{t(setting.text)}</p>
                </div>
                </NavLink>
            })}
            </div>
        </div>
    </div>
}
export default withNamespaces((props) => props.namespaces)(Setting);