import setting_icon from "./img/uil_setting.png";
import noti_icon from "./img/noti_icon.svg";
import FixedBottomNavigation from "../BottomNavigation/BottomNavigation";
import i18n from "../../i18n";
import { withNamespaces } from "react-i18next";
import { useEffect, useState } from "react";
import { BackIcon } from "../common/Icons";
import { NavLink } from "react-router-dom";
import { NotificationContext } from "./UserProfile";
import { twJoin } from "tailwind-merge";
const UserProfileWithBack = (props: any) => {
  const [notificationList, setNotificationList] = useState<any>([]);
  const language = localStorage.getItem("language");
  useEffect(() => {
    i18n.changeLanguage(language ? language : "en");
  }, []);
  const changeNotificationData = (data: any) => {
    setNotificationList(data);
  };
  const noticount = notificationList?.filter(
    (x: any) => x.is_read == false
  )?.length;
  return (
    <div className="max-w-full">
      <div className="max-w-full mx-auto overflow-hidden">
        <div className="mb-[60px]">
          <div className="max-w-full fixed-nav-bar flex items-center justify-between w-full px-5 fixed top-0 left-1/2 -translate-x-1/2 py-4 z-10 bg-white">
            <button onClick={() => window.history.back()}>
              <BackIcon />
            </button>
            <h1 className="text-body-md font-normal text-center">
              {props?.title}
            </h1>
            <NavLink to={{pathname:"/notifications"}} className="relative">
              <img src={noti_icon} alt="noti_icon" />
              <div
                className={twJoin(
                  noticount <= 0 ? "hidden" : "",
                  "bg-sat_primary inline-block rounded-full aspect-square absolute right-0 top-0 p-[2px] w-[12px] h-[12px]"
                )}
              >
                <span className="text-white text-[9px] font-bold block leading-[100%] tracking-[-0.18px] whitespace-nowrap">
                  {noticount}
                </span>
              </div>
            </NavLink>
          </div>
        </div>
        <div className="px-5 mt-4">
          <NotificationContext.Provider
            value={{ notificationList, changeNotificationData }}
          >
            {props?.children}
          </NotificationContext.Provider>
        </div>
      </div>
    </div>
  );
};
export default withNamespaces((props) => props.namespaces)(UserProfileWithBack);
