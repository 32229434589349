import { initializeApp } from 'firebase/app';
import { api, endpointUrl, getLanguageCode, getLanguageCodeForbackend } from './api';
import authHeader from './auth-header';
import { deleteToken, getMessaging } from 'firebase/messaging';

export type AnnounSheetDataResponse = {
    categories:any,
    departments:any,
    description:string,
    employees:string,
    file_url:string,
    expired_at:string,
    name:string,
    readStatus:boolean,
    id:number,
};


export type NoticeBoardDataResponseCus = {
    announcementList: AnnounSheetDataResponse[],
    module_id: number,
};
export type AnnounSheet = {
    id: number,
    title: string,
    tag: string[],
    postedDate: string,
    expiryDate: string,
    postedBy: string,
    updatedBy: string,
    type: string,
    viewBy?: [],
    content: string,
    data?: any,
}

export type TagsResponse = {
    tagsData: TagsData[]
}
export type TagsResponseCustom = {
    tagList: TagsData[]
}
export type TagsData = {
    id: number,
    name: string,
    flag: string
}

export type CreateeAnnounSheet = {
    id?: number,
    title: string,
    content: string
    tags: number[],
    expired_at: string,
    postedDate?: string,
    postedBy?: string,
    updatedBy?: string,
    typeData: string
}


export type TemplateData = {
    id: number;
    name: string;
    viewColumns: string[];
};

export type ErrorState = {
    status?: number | string,
    data?: any | undefined,
    error?: string
}
export type TagInput = {
    name: string;
    flag: string;
}
export type PaginationData = {
    category: string | number | undefined,
    year:string
}
export const deleteNotiToken = () => {
    // const app = initializeApp(firebaseConfig);
    // const messaging = getMessaging(app);
    // deleteToken(messaging).then((res) => {
    // }).catch(function (error) {
    //     console.error('Error deleting FCM token:', error);
    //   });;
}
export const redirectToLogin = () => {
    localStorage.removeItem("userData");
    window.location.href = '/';
    deleteNotiToken();
}
export const notification_api = api.injectEndpoints({
    endpoints: (build) => ({
        getNotifications: build.query<AnnounSheetDataResponse, void>({
            query: () => ({
                url: `${endpointUrl}getannouncements/`,
                headers: authHeader()
            }),
            keepUnusedDataFor:0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: ['NoticeBoard'],
        }),
        readNoticeBoard: build.query<AnnounSheetDataResponse, number>({
            query: (id) => ({
                url: `${endpointUrl}${getLanguageCodeForbackend()}readnoticeboard/${id}`,
                method: 'GET',
                headers: authHeader(),
            }),
            providesTags: ['NoticeBoard'],
        }),
        getNoticeBoardDataByID: build.query<AnnounSheetDataResponse, number>({
            query: (id) => ({
                url: `${endpointUrl}noticeboards/detail/${id}`,
                headers: authHeader()
            }),
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: ['NoticeBoard'],
        }),
        getNoticationsData: build.query<NoticeBoardDataResponseCus, void>({
            query: () => ({
                url: `${endpointUrl}${getLanguageCodeForbackend()}getannouncements`,
                headers: authHeader()
            }),
            keepUnusedDataFor:0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: ['NoticeBoard'],
        }),
        createNoticeBoard: build.mutation<CreateeAnnounSheet, Partial<CreateeAnnounSheet>>({
            query: (data) => ({
                url: `${endpointUrl}noticeboards`,
                method: "POST",
                headers: authHeader(),
                body: data,
            }),
            invalidatesTags: () => [{ type: 'NoticeBoard' }],
        }),
        updateNoticeBoard: build.mutation<CreateeAnnounSheet, Partial<CreateeAnnounSheet>>({
            query: ({ id, ...rest }) => {
                return {
                    url: `${endpointUrl}noticeboards/${id}`,
                    method: "PUT",
                    headers: authHeader(),
                    body: rest,
                }

            },
            invalidatesTags: () => [{ type: 'NoticeBoard' }],
        }),        
        getNotificationsData: build.mutation<{ id: number }, number>({
            query: (id) => ({
                url: `${endpointUrl}${getLanguageCodeForbackend()}getannouncements`,
                method: 'GET',
                headers: authHeader(),                
            }),            
            invalidatesTags: ['NoticeBoard'],
        }),
        deleteNoticeBoard: build.mutation<{ id: number }, number>({
            query: (id) => ({
                url: `${endpointUrl}noticeboards/${id}`,
                method: 'DELETE',
                headers: authHeader(),
            }),
            invalidatesTags: ['NoticeBoard'],
        }),
        readNotificationDetail: build.mutation<{ id: number }, number>({
            query: (id) => ({
                url: `${endpointUrl}${getLanguageCodeForbackend()}readannouncement/${id}`,
                method: 'GET',
                headers: authHeader(),
            }),
            invalidatesTags: ['NoticeBoard'],
        }),
        multiDeleteNoticeBoard: build.mutation<CreateeAnnounSheet, Partial<CreateeAnnounSheet>>({
            query: ({ id, ...rest }) => ({
                url: `${endpointUrl}noticeboards`,
                headers: authHeader(),
                method: 'DELETE',
                body: rest
            }),
            invalidatesTags: ['NoticeBoard'],
        }),
    })
});


export const {
    useGetNotificationsQuery,
    useGetNoticationsDataQuery,
    useCreateNoticeBoardMutation,
    useUpdateNoticeBoardMutation,
    useDeleteNoticeBoardMutation,
    useMultiDeleteNoticeBoardMutation,
    useGetNoticeBoardDataByIDQuery,
    useReadNoticeBoardQuery,
    useReadNotificationDetailMutation,
    useGetNotificationsDataMutation
} = notification_api;

export const {
    endpoints: {
         getNotifications,
         getNoticationsData,
         createNoticeBoard,
         updateNoticeBoard,
         deleteNoticeBoard,
         multiDeleteNoticeBoard,
         getNoticeBoardDataByID,
         readNoticeBoard,
         readNotificationDetail,
         getNotificationsData
    },
} = notification_api;