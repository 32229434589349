import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useGetUsefulLinesQuery } from "../../app/services/usefullines";
import { NavLink } from "react-router-dom";

const UsefulLines = () => {
  const [usefulLinesData, setUsefulLinesData] = useState<any>([]);
  const { data: usefulLines, isLoading } = useGetUsefulLinesQuery();
  useEffect(() => {
    if (usefulLines) {
      setUsefulLinesData(usefulLines?.usefulLinkList);
    }
  }, [usefulLines]);
  return (
    <div className=" -mx-5">
      {isLoading == true ? (
        <CircularProgress />
      ) : (
        <ul>
          {usefulLinesData?.map((link: any, key: number) => {
            const linkdata=link.link?.includes(' ')?'/profile/useful_lines/'+link.id:link.link;
            const target=link.link?.includes(' ')?'_self':'_blank';
            return (
              <li className="p-5 w-full text-left border-b border-b-sat_borderv2">
                {link.type=="Text" ? (
                  <NavLink state={{
                    data: {
                      ...link,
                    },
                  }}
                  to={{pathname:""+link.id}}
                    className="text-sat_link underline underline-offset-2 text-body-md w-full"
                  >
                    {link.name}
                  </NavLink>
                ) : (
                  <a
                    target="_blank"
                    href={linkdata}
                    className="text-sat_link underline underline-offset-2 text-body-md w-full"
                  >
                    {link.name}
                  </a>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
export default UsefulLines;
