const NotificationDetail = () => {
  const data = window.history.state?.usr?.data;
  return (
    <div>
      <div className="">
        <div className="mb-5 pb-5 border-b border-b-sat_borderv2">
          <p className="text-body-md font-bold my-[10px] text-sat_darkblackv2 text-left">
            {data?.title}
          </p>
          <h1 className="text-body-sm font-normal text-sat_darkgray400 text-left">
            {data?.expiryDate}
          </h1>
        </div>  
        <div className="text-body-sm font-normal text-sat_darkgray leading-[150%] text-left">
            <div dangerouslySetInnerHTML={{ __html: data?.content }}></div>
        </div>      
      </div>
     
    </div>
  );
};
export default NotificationDetail;
