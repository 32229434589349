import { useEffect, useState } from "react";
import "./App.scss";
import MainRouter from "./MainRouter";
import {
  //requestPermission,
  serverkey,
} from "./app/services/firebase";
import { getAuthUser } from "./app/services/dashboard";
import { useNavigate } from "react-router-dom";
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import "./i18n";
import { withNamespaces } from "react-i18next";
import axios from "./axios";
import { endpointUrl } from "./app/services/api";
import { SafeArea } from "capacitor-plugin-safe-area";
import { Capacitor } from "@capacitor/core";
function App() {
  const navigate = useNavigate();
  // console.log("app div", window, navigator, isSupported());
  FirebaseMessaging.requestPermissions().then((res) => {
    console.log("request permission ", res);
  });
  FirebaseMessaging.addListener("notificationReceived", function (payload) {
    console.log("notificationReceived ", payload.notification);
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
      link: payload.notification.link,
      id: payload.notification.id,
    };
  });
  FirebaseMessaging.addListener(
    "notificationActionPerformed",
    function (action) {
      console.log("notificationActionPerformed ", JSON.stringify(action));
    }
  );
  function callChannel(token: any) {
    var getUser = getAuthUser();
    if (getUser?.token) {
      axios(endpointUrl + "admin/fcm-token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUser.token}`,
        },
        data: {
          token: token,
        },
      }).then((res) => {
        // console.log("res ", res)
      });
    }
  }

  useEffect(() => {
    var getUser = getAuthUser();
    if (getUser?.token) {
      //requestPermission();
      FirebaseMessaging.getToken({ vapidKey: serverkey }).then((res) => {
        console.log("getToken ", res.token);
        callChannel(res.token);
      });
    }

    // const unsubscribe = onMessageListener().then((payload:any) => {
    //   setNotification({
    //     title: payload?.notification?.title,
    //     body: payload?.notification?.body,
    //   });
    //   // toast.success(`${payload?.notification?.title}: ${payload?.notification?.body}`, {
    //   //   duration: 60000,
    //   //   position: 'top-right',
    //   // });
    // });
    // return () => {
    //   unsubscribe.catch((err) => console.log('failed: ', err));
    // };
    // console.clear();
  }, []);
  const safeArea = async () => {
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      const body = document.querySelector("body");
      const body_content: any = document.querySelector(".body-content");
      const fixedbar: any = document.querySelector(".fixed-nav-bar");
      const bottom_bar: any = document.querySelector(".bottom-navigation");
      if (body != null) {
        body.style.paddingTop = (insets ? (insets.top==0?20:insets.top) : 20) + "px";
      }
      if (fixedbar) {
        fixedbar.style.paddingTop = (insets ? (insets.top==0?20:insets.top) : 20) + "px";
      }
      if (bottom_bar != null) {
        bottom_bar.style.paddingBottom = (insets ? insets.bottom : 20) + "px";
      }
      if (body_content != null) {
        body_content.style.paddingBottom = (insets ? insets.bottom : 20) + "px";
      }
    });

    SafeArea.getStatusBarHeight().then((statusBarHeight: any) => {
      console.log(statusBarHeight, "statusbarHeight");
      const body = document.querySelector("body");
      const fixedbar: any = document.querySelector(".fixed-nav-bar");
      const bottom_bar: any = document.querySelector(".bottom-navigation");
      // if(body!=null){
      //   body.style.paddingTop=(statusBarHeight?(statusBarHeight+20):0)+'px';
      // }
      // if(fixedbar){
      //   fixedbar.style.paddingTop=(statusBarHeight?statusBarHeight:20)+'px';
      // }
    });

    await SafeArea.removeAllListeners();

    // when safe-area changed
    await SafeArea.addListener("safeAreaChanged", (data) => {
      const { insets } = data;
      console.log("change safe area");
      for (const [key, value] of Object.entries(insets)) {
        document.documentElement.style.setProperty(
          `--safe-area-inset-${key}`,
          `${100}px`
        );
      }
    });
  };
  safeArea();
  
  return (
    <div className="App overflow-hidden">
      <MainRouter />
    </div>
  );
}
export default withNamespaces()(App);
