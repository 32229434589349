import password_logo from "../../login/img/sat-logo.svg";
import close_icon from "./img/majesticons_close.svg";
import qr_image from "./img/login-qr.png";
import { useState } from "react";
import { twJoin } from "tailwind-merge";
import styles from "./QRForm.module.scss";
import NoticePopup from "../NoticePopup/NoticePopup";
import { logout } from "../../app/services/auth.service";
import { NavLink, useNavigate } from "react-router-dom";
const QRForm = (props:any) => {
  const {logoData}=props;
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState<string>("ClockIn");
  var userData = localStorage.getItem("userData");
  var userJson: any = {};
  if (userData) {
    userJson = JSON.parse(userData);
    userJson.token = userJson.accessToken;
  }
  const handleLogout=()=>{
    logout();
    navigate('/login');
  }
  return (
    <div className="max-w-full h-[92vh] overflow-hidden">
      <div className="max-w-[360px] mx-auto">
        <div className="change-password-container px-5">
          <div className="py-5 relative left-0 w-full top-0 bg-white">
            <h3 className="heading3 font-secondary text-center text-sat_darkblackv2 w-full">
              My QR Code
            </h3>
            <NavLink to={{pathname:"/profile"}} className="absolute right-5 top-1/2 -translate-y-1/2">
              <img src={close_icon} alt="back" className="" />
            </NavLink>
          </div>
          <div className="pt-8 pb-8 h-auto flex flex-col justify-between">
            <div>
              <img
                src={logoData?.logo?logoData?.logo:password_logo}
                alt="password_logo"
                className="mx-auto max-w-full"
              />
              <div className="mt-10">
                <div
                  className={twJoin(
                    styles.clock_in_out_buttons,
                    "grid grid-cols-2 gap-[14px] mb-8"
                  )}
                >
                  <button
                    onClick={() => setActiveButton("ClockIn")}
                    className={twJoin(
                      activeButton == "ClockIn"
                        ? "bg-sat_primary text-white"
                        : "bg-white text-sat_grayHint",
                      "rounded-lg py-3 px-5  text-center font-bold text-button"
                    )}
                  >
                    CLOCK IN
                  </button>
                  <button
                    onClick={() => setActiveButton("ClockOut")}
                    className={twJoin(
                      activeButton == "ClockOut"
                        ? "bg-sat_primary text-white"
                        : "bg-white text-sat_grayHint",
                      "rounded-lg py-3 px-5 text-center font-bold text-button"
                    )}
                  >
                    CLOCK OUT
                  </button>
                </div>
                <div className="border border-sat_border rounded-xl bg-white p-5 block w-full">
                  <div
                    className={twJoin(
                      activeButton == "ClockIn"
                        ? "border-sat_greenlight"
                        : "border-sat_red_light",
                      "p-[14px] border-4  bg-white block relative rounded-xl"
                    )}
                  >
                    <div className="">
                      <img
                        src={qr_image}
                        alt="qr_image"
                        className="max-w-full object-cover w-full mx-auto"
                      />
                    </div>
                  </div>
                  <div className="text-left mt-3 flex items-center justify-between">
                    <div>
                      <p className="text-body-23 text-sat_darkblackv2 font-bold">
                        {userJson?.user?.name}
                      </p>
                      <p className="text-button text-sat_graydark font-normal">
                      {userJson?.user?.employee?.employeeID}
                      </p>
                    </div>
                    <div>
                      <p
                        className={twJoin(
                          activeButton == "ClockIn"
                            ? "bg-sat_greenDark"
                            : "bg-sat_primary",
                          "text-white px-3 py-1 rounded-[13px] font-bold text-body-23"
                        )}
                      >
                        {activeButton == "ClockIn" ? "IN" : "OUT"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>      
    </div>
  );
};
export default QRForm;
