import React, { useState } from "react";
import back from "../../forgetpassword/img/back-page.svg";
import password_logo from "../../forgetpassword/img/password-logo.png";
import { useNavigate } from "react-router-dom";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import { twJoin } from "tailwind-merge";

export const switchEyeHandler = (event: any) => {
  const type =
    event.target
      .closest(".password-div")
      .querySelector("input")
      .getAttribute("type") === "password"
      ? "text"
      : "password";
  const text =
    event.target
      .closest(".password-div")
      .querySelector("input")
      .getAttribute("type") === "password"
      ? "Hide"
      : "Show";
  event.target
    .closest(".password-div")
    .querySelector("input")
    .setAttribute("type", type);
  event.target.textContent = text;
};
const ResetPasswordComponent = (props:any) => {
  const {logoData}=props;  
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState("");
  const [saving,setSaving]=useState<boolean>(false);
  const resetPassword = () => {
    var { new_password, confirm_password } = document.forms[0];
    var userData = localStorage.getItem("userData");
    //var userJson=userData?JSON.parse(userData):{};
    const params = new URLSearchParams(window.location.search);
    var email = params.get("email");
    var token = params.get("token");
    var values = {
      token:token,
      email: email,
      password: new_password?.value,
      password_confirmation: confirm_password.value,
    };
    setSaving(true);
    axios
      .post(endpointUrl + "password/reset", values, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        if (response.status == 200) {
          setLoginError('');
          setSaving(false);
          navigate("/resetpassword-success");
        } else {
          setSaving(false);
        }
        //var data = response.data;
      })
      .catch((reason) => {
        // Object.keys(reason.response.data.errors).forEach(key => {
        //   const d = reason.response.data.errors[key];
        //   console.log(d);
        // })
        setSaving(false);
        var error = reason.response.data.message;
        //setErrorPopup(true);
         setLoginError(error);
       // setMessage([error]);
      });
    
  };
  return (
    <div className="max-w-full">
      <div className="max-w-[360px] mx-auto px-5">
        <div className="change-password-container">
          <div className="py-5 relative left-0 w-full top-0 bg-white">
            <button
              onClick={() => window.history.back()}
              className="absolute left-0 top-1/2 -translate-y-1/2"
            >
              <img src={back} alt="back" className="" />
            </button>
            <h3 className="heading3 font-secondary text-center text-sat_darkblackv2 w-full">
              Create New Password
            </h3>
          </div>
          <div className="pt-16 pb-10 h-[90vh] flex flex-col justify-between">
            <div>
              <img
                src={password_logo}
                alt="password_logo"
                className="mx-auto max-w-full"
              />
              <div className="mt-10">
                <p className="text-body-16 font-normal text-sat_darkgray text-center">
                  This password should be different from the previous password.
                </p>
                <form action="" className="w-full mt-5">
                  <div className="password-div mb-5 flex justify-between items-center overflow-hidden bg-sat_lightgray border border-sat_lightgray rounded-lg relative">
                    <input
                      type="password"
                      name="new_password"
                      placeholder="New Password"
                      className="text-body-md w-full font-secondary px-3 py-[10px] focus-within:outline-none focus:outline-none focus-visible:outline-none placeholder:text-sat_grayHint"
                    />
                    <button
                      onClick={(event: any) => switchEyeHandler(event)}
                      type="button"
                      className="text-body-sm text-sat_darkgray font-secondary px-3 absolute right-0 top-1/2 -translate-y-1/2"
                    >
                      Show
                    </button>
                  </div>
                  <div className="password-div mb-5 flex justify-between items-center overflow-hidden bg-sat_lightgray border border-sat_lightgray rounded-lg relative">
                    <input
                      type="password"
                      name="confirm_password"
                      placeholder="Confirm Password"
                      className="text-body-md w-full font-secondary px-3 py-[10px] focus-within:outline-none focus:outline-none focus-visible:outline-none placeholder:text-sat_grayHint"
                    />
                    <button
                      onClick={(event: any) => switchEyeHandler(event)}
                      type="button"
                      className="text-body-sm text-sat_darkgray font-secondary px-3 absolute right-0 top-1/2 -translate-y-1/2"
                    >
                      Show
                    </button>
                  </div>
                  <p className="text-body-sm font-normal text-center text-sat_primary my-2">{loginError}</p>
                  <button
                    type="button"
                    onClick={() => resetPassword()}
                    className={twJoin(saving==true?'opacity-50 pointer-events-none':'',"rounded-lg py-3 px-3 text-white text-center text-body-md font-bold font-secondary w-full bg-sat_primary")}
                  >
                    Reset Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordComponent;
